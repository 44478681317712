import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app-container">
      <header className="app-header">
        <h1 className="app-title">hey, i'm mcarthur.</h1>
        <p className="app-subtitle">currently building&nbsp;
          <a
            className="app-link"
            href="https://www.swan.com"
            target="_blank"
            rel="noopener noreferrer"
          >swan.com</a> &#129442;
        </p>
        <p className="app-text">
          contact me on&nbsp;
          <a
            className="app-link"
            href="nostr:npub16keel7dgcxv02ndxm34k6aaaev0055zv0t8gfxl2glq8293yzhvqfnazag"
            target="_blank"
            rel="noopener noreferrer"
          >
            nostr
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
